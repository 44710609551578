import { en } from "./en";

export const fr: typeof en = {
  navBar: {
    home: "Accueil",
    categories: "Catégories",
    about: "À propos",
  },
  lang: {
    en: "EN",
    fr: "FR",
  },
  categories: {
    Gaming: "Gaming",
    Development: "Développement",
    Ramblings: "Divagations",
    Meta: "Meta",
  },
  generic: {
    minRead: "minutes",
    comments: "Commentaires",
    categories: "Catégories: ",
    category: "Catégorie: ",
    noDisqus: "Pas de Disqus dans cet environnement.",
    unlisted1:
      "Cet article n'est pas répertorié: seuls ceux qui ont ce lien direct peuvent y accéder.",
    unlisted2: "Soyez attentif avec qui vous le partagez.",
    unavailableLanguage: "Cet article n'est disponible qu'en anglais.",
  },
  pages: {
    home: {
      postsTitle: "Posts",
    },

    404: {
      title: "Erreur 404",
      subtitle: "Page not found",
      content: (
        <>
          <p>La page que vous recherchez n'existe pas (ou plus).</p>
          <p>
            Si vous êtes arrivé ici en suivant un lien, n'hésitez pas à{" "}
            <a
              href="https://twitter.com/Protectator"
              target="_blank"
              rel="noreferrer"
            >
              me le dire
            </a>
            !
          </p>
        </>
      ),
    },
    500: {
      title: "Erreur 500",
      subtitle: "Internal Server Error",
      content: (
        <>
          <p>
            Oups, une erreur est survenue et cette page ne peut pas être
            affichée.
          </p>
          <p>
            Si vous êtes arrivé ici en suivant un lien, n'hésitez pas à{" "}
            <a
              href="https://twitter.com/Protectator"
              target="_blank"
              rel="noreferrer"
            >
              me le dire
            </a>
            !
          </p>
        </>
      ),
    },
    about: {
      moreInfo1: (
        <p>
          Pour d'avantage d'informations, consultez mon site personnel à
          l'adresse <a href="https://www.kewindousse.ch/">kewindousse.ch</a> (en
          anglais).
        </p>
      ),
      moreInfo2: (
        <p>
          Le moyen le plus simple de me contacter est sur twitter{" "}
          <a href="https://twitter.com/Protectator">@Protectator</a>.
        </p>
      ),
    },
  },
};
