import Script from "next/script";
import { PropsWithChildren } from "react";

import Footer from "./Footer";
import NavBar from "./NavBar";
import HeadTags, { HeadTagsProps } from "./head/HeadTags";

type LayoutProps = PropsWithChildren<{
  title?: string;
  description?: string;
  noIndex?: boolean;
  image?: string;
  jsonLd?: HeadTagsProps["jsonLd"];
}>;

export const Layout = ({
  title,
  description,
  noIndex,
  image,
  children,
  jsonLd,
}: LayoutProps) => {
  return (
    <main>
      <HeadTags
        title={title}
        description={description}
        noIndex={noIndex}
        image={image}
        jsonLd={jsonLd}
      />
      <Script
        id="ga-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
          (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
          m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

          ga('create', 'UA-58143757-2', 'auto');
          ga('set', 'anonymizeIp', true);
          ga('send', 'pageview');`,
        }}
      />
      <NavBar />
      {children}
      <Footer />
    </main>
  );
};
