export const blogDescription =
  "This blog is a place where I post my thoughts about various subjects mostly related to either programming or gaming stuff.";
export const blogDescriptionFr =
  "Ce blog est un endroit où je publie mes pensées sur divers sujets, principalement liés à la programmation et aux jeux vidéo.";
export const blogTitle = "Protectator";
export const blogAuthorName = "Kewin Dousse";
export const blogAuthorEmail = "me@protectator.ch";
export const blogKeywords =
  "Protectator, blog, programming, gaming, code, Kewin, Dousse";
export const blogCopyrightName = "Kewin Dousse";
export const blogCopyrightYears = "2014-2024";
