import Head from "next/head";
import { useRouter } from "next/router";
import type { Blog, BlogPosting, AboutPage, CollectionPage } from "schema-dts";

import { ORIGIN } from "../../../src/utils";

import {
  blogAuthorEmail,
  blogAuthorName,
  blogCopyrightName,
  blogCopyrightYears,
  blogKeywords,
  blogTitle,
  blogDescription,
  blogDescriptionFr,
} from "./values";

export type HeadTagsProps = {
  title?: string;
  description?: string;
  noIndex?: boolean;
  image?: string;
  jsonLd?: Blog | BlogPosting | AboutPage | CollectionPage;
};

export default function HeadTags(props: HeadTagsProps) {
  const { locale, asPath } = useRouter();
  const noNullProps = Object.fromEntries(
    Object.entries(props).filter(([_, v]) => v != null)
  );

  const defaults: HeadTagsProps = {
    title: blogTitle,
    description: locale === "fr" ? blogDescriptionFr : blogDescription,
    noIndex: false,
  };

  const { title, description, noIndex, image } = {
    ...defaults,
    ...noNullProps,
  };

  const jsonLdWithContext = {
    "@context": "https://schema.org",
    ...props.jsonLd,
  };

  return (
    <Head>
      <title key="title">
        {title !== defaults.title ? `${title} - ${defaults.title}` : title}
      </title>
      <meta key="og:title" content={title} property="og:title" />

      <meta
        key="og:description"
        content={description}
        property="og:description"
      />
      <meta key="description" content={description} name="description" />

      <meta name="Keywords" lang={locale} content={blogKeywords} />
      <meta name="author" content={blogAuthorName} />
      <meta name="Rev" content={blogAuthorEmail} />
      <meta
        name="copyright"
        content={`${blogCopyrightName} ${blogCopyrightYears}`}
      />

      <meta content="profile" property="og:type" />
      <meta content="Kewin" property="og:profile:first_name" />
      <meta content="Dousse" property="og:profile:last_name" />
      <meta content="Protectator" property="og:profile:username" />
      <meta content="male" property="og:profile:gender" />
      <meta content="Protectator" property="og:site_name" />

      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#2f2f32" />
      <link rel="icon" href="/favicon.ico" />
      <link rel="shortcut icon" type="image/png" href="/favicon.png" />

      {asPath && (
        <>
          <link rel="alternate" hrefLang="en" href={`${ORIGIN}${asPath}`} />
          <link rel="alternate" hrefLang="fr" href={`${ORIGIN}/fr${asPath}`} />
        </>
      )}

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdWithContext) }}
      />

      {noIndex ? (
        <meta key="robots" content="noindex,noarchive" name="robots" />
      ) : (
        <meta key="robots" name="robots" content="all" />
      )}

      {image ? <meta content={image} property="og:image" /> : null}
    </Head>
  );
}
