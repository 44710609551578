import type { Post } from "../.tina/__generated__/types";

import { useT } from "./translations";

export function displayDate(stringDate: string, locale: string) {
  return new Date(stringDate).toLocaleDateString(locale, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

function nodeWordCount(node: Post["body"]) {
  if ("text" in node) {
    return node.text.split(" ").length;
  } else if ("children" in node) {
    return node.children.reduce((acc, child) => {
      return acc + nodeWordCount(child);
    }, 0);
  }
  return 1;
}

// Counts the amount of words in the Tina body document
export function bodyWordCount(body: Post["body"]) {
  return nodeWordCount(body);
}

const WPM_READING_RATE = 225;

export const ORIGIN = "https://protectator.ch";

export function displayReadTime(
  post: Post["body"],
  t: ReturnType<typeof useT>
) {
  const words = bodyWordCount(post);
  const minutes = Math.floor(words / WPM_READING_RATE);
  return `${minutes > 0 ? minutes : 1} ${t.generic.minRead}`;
}

export function isDraftDev(post: Pick<Post, "status">) {
  return post.status === "draft" && process.env.NODE_ENV === "development";
}

export function isListable(post: Pick<Post, "status">) {
  return post.status === "publish" || isDraftDev(post);
}

export function isDisplayable(post: Post) {
  return (
    post.status === "publish" || post.status === "unlisted" || isDraftDev(post)
  );
}

export function getSortedListablePosts<T extends Array<Post>>(nodes: T) {
  return nodes
    .filter((post) => isListable(post))
    .sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    })
    .reverse();
}

const BASE_IMAGES_PATH = "/static/post_header";

export function getImageSrc(fileName: string) {
  return {
    og: `${BASE_IMAGES_PATH}/out/${fileName}_og.jpg`,
    post: `${BASE_IMAGES_PATH}/out/${fileName}_post.jpg`,
    post_webp: `${BASE_IMAGES_PATH}/out/${fileName}_post.webp`,
    list: `${BASE_IMAGES_PATH}/out/${fileName}_list.jpg`,
    list_webp: `${BASE_IMAGES_PATH}/out/${fileName}_list.webp`,
  };
}

export function getImageFullUrl(fileSrc: string) {
  return `${ORIGIN}${fileSrc}`;
}
