export const en = {
  navBar: {
    home: "Home",
    categories: "Categories",
    about: "About",
  },
  lang: {
    en: "EN",
    fr: "FR",
  },
  categories: {
    Gaming: "Gaming",
    Development: "Development",
    Ramblings: "Ramblings",
    Meta: "Meta",
  },
  generic: {
    minRead: "min read",
    comments: "Comments",
    categories: "Categories: ",
    category: "Category: ",
    noDisqus: "No Disqus in this environment.",
    unlisted1:
      "This post is unlisted: only those with this direct link can access it.",
    unlisted2: "Be careful who you share it with.",
    unavailableLanguage: "This post is only available in French.",
  },
  pages: {
    home: {
      postsTitle: "Posts",
    },
    404: {
      title: "Error 404",
      subtitle: "Page not found",
      content: (
        <>
          <p>The page you're looking for doesn't exist (anymore).</p>
          <p>
            If you ended up here by following a link, don't hesitate to{" "}
            <a
              href="https://twitter.com/Protectator"
              target="_blank"
              rel="noreferrer"
            >
              tell me
            </a>
            !
          </p>
        </>
      ),
    },
    500: {
      title: "Error 500",
      subtitle: "Internal Server Error",
      content: (
        <>
          <p>Oops, I messed up something and this page can't be displayed.</p>
          <p>
            If you ended up here by following a link, don't hesitate to{" "}
            <a
              href="https://twitter.com/Protectator"
              target="_blank"
              rel="noreferrer"
            >
              tell me
            </a>
            !
          </p>
        </>
      ),
    },
    about: {
      moreInfo1: (
        <p>
          If you're looking for more information, you might find it on my
          personal website at{" "}
          <a href="https://www.kewindousse.ch/">kewindousse.ch</a>.
        </p>
      ),
      moreInfo2: (
        <p>
          If you want to contact me, you should probably try to tweet{" "}
          <a href="https://twitter.com/Protectator">@Protectator</a>.
        </p>
      ),
    },
  },
};
