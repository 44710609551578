import styled, { css } from "styled-components";

export const boxStyle = css`
  background-color: rgba(45, 45, 48, 1);
  border-radius: var(--border-radius);
  padding: 0 var(--panel-padding);
  margin: var(--panel-padding) 0;
  box-shadow: var(--box-shadow);

  article & > :first-child {
    padding-top: var(--panel-padding);
  }

  article & > :last-child {
    padding-bottom: var(--panel-padding);
  }
`;

export const BoxDiv = styled.div`
  background-color: rgba(45, 45, 48, 1);
  border-radius: var(--border-radius);
  padding: 0 var(--panel-padding);
  margin: var(--panel-padding) 0;
  box-shadow: var(--box-shadow);

  article & > :first-child {
    padding-top: var(--panel-padding);
  }

  article & > :last-child {
    padding-bottom: var(--panel-padding);
  }
`;
export const BoxSection = styled.section`
  background-color: rgba(45, 45, 48, 1);
  border-radius: var(--border-radius);
  padding: 0 var(--panel-padding);
  margin: var(--panel-padding) 0;
  box-shadow: var(--box-shadow);

  article & > :first-child {
    padding-top: var(--panel-padding);
  }

  article & > :last-child {
    padding-bottom: var(--panel-padding);
  }
`;
