import Link from "next/link";
import { useRouter } from "next/router";
import styled from "styled-components";

import { useT } from "../../src/translations";
import { boxStyle } from "../Box";

const Snav = styled.nav`
  ${boxStyle}
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  justify-items: center;
  margin: 0 !important;
  padding: var(--navbar-padding);
  position: relative;

  & div {
    white-space: nowrap;
  }
`;

const AboutDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const LanguageDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: calc(-2 * var(--navbar-padding));
`;

export default function NavBar() {
  const router = useRouter();
  const { asPath, locale } = router;

  const t = useT();

  return (
    <>
      <Snav>
        <div>
          <Link href="/">{t.navBar.home}</Link>
        </div>
        <div>
          <Link href="/categories">{t.navBar.categories}</Link>
        </div>
        <AboutDiv>
          <div></div>
          <Link href="/about">{t.navBar.about}</Link>
          <LanguageDiv>
            {locale === "fr" && (
              <Link href={asPath} locale="en">
                EN
              </Link>
            )}
            {locale === "en" && (
              <Link href={asPath} locale="fr">
                FR
              </Link>
            )}
          </LanguageDiv>
        </AboutDiv>
      </Snav>
      <header>
        <h1>
          <span className="base">Protectator</span>
        </h1>
      </header>
    </>
  );
}
