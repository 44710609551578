import { useRouter } from "next/router";

import { en } from "../content/i18n/translations/en";
import { fr } from "../content/i18n/translations/fr";

export const t = (locale: string) => {
  if (locale === "fr") {
    return fr;
  }
  return en;
};

export const useT = () => {
  const { locale } = useRouter();
  return t(locale);
};
